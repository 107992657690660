/**
 * Site header
 */
.site-header {
  border-top: 5px solid $brand-color;
  border-bottom: 5px solid $yellow-color;
  min-height: $spacing-unit * 1.865;
}

.site-logo {
  margin: $spacing-unit auto 1em auto;
  width: 50%;
  @include media-query($on-palm) {
    width: 80%;
  }
}

.site-desc {
  text-align: center;
  padding-bottom: $spacing-unit;
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  color: $grey-color;
}


/**
 * Site footer
 */
.site-footer {
  border-top: 5px solid $yellow-color;
  padding: $spacing-unit 0;
}

.footer-heading {
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
  margin-bottom: $spacing-unit;

  @include media-query($on-palm) {
      display: block;
  }

  .footer-links {
    @include relative-font-size(1.125); 
    color: $text-color;
    font-weight: 300;

    &.footer-left {
      text-align: right;
      @include media-query($on-palm) {
        text-align: center;  
      }
    }

    &.footer-right {
      text-align: left;
      @include media-query($on-palm) {
        text-align: center;  
      }
    }    
  }

  .footer-divider {
    text-align: center;
    margin-top: 7px;
    @include media-query($on-palm) {
      margin-top: 1em;
      margin-bottom: 1em;  
    }
    
    .footer-bullets {
      color: $brand-color;
      font-weight: 300;
      font-size: 1.5em;
      line-height: 10px;
    }
  }

}

.footer-disclaimer {
  color: $grey-color;
  font-style: italic;
  text-align: center;
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: $spacing-unit;
  letter-spacing: 1px;
}

.footer-rss {
  text-align: center;
  font-size: 0.8em;
  font-weight: 300;
}


/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  margin-top: $spacing-unit;

  .post-thumb {
    width: calc(calc(100% / 2) - #{$spacing-unit});
    display: inline;
    float: left;

    img {
      object-fit: cover;
      width:100%;
      height: 300px;
    }

    @include media-query($on-palm) {
      width: 100%;
    }

  }

  .post-thumb:nth-child(odd) {
      padding-right: $spacing-unit * 2;

      @include media-query($on-palm) {
        padding-right: 0;
      }
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1);
  padding-top: 1em;

  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
}

.post-link:after {
  content: '';
  display: block;
  width: 3em;
  height: 5px;
  margin-top: 1em;
  background-color: $brand-color;
}

.post-summary {
  padding-bottom: $spacing-unit * 2;
  @include relative-font-size(0.9);
}


/**
 * Posts
 */

.post-header {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
  font-weight: 400;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-meta {
  padding-top: 0.5em;
  text-align: center;
  color: $grey-color-light;
}

.post-meta:after {
  content: '';
  display: block;
  width: 3em;
  height: 5px;
  margin: 1em auto 1em auto;
  background-color: $brand-color;
}

.post-content {
  max-width: 650px;
  margin: auto auto ($spacing-unit * 2) auto;

  h2 {
    @include relative-font-size(1.6);
    font-weight: 700;

    @include media-query($on-laptop) {
      @include relative-font-size(1.5);
    }
  }

  h3 {
    @include relative-font-size(1.3);
    font-weight: 500;

    @include media-query($on-laptop) {
      @include relative-font-size(1.2);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

.post-navigation {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
  @include relative-font-size(0.9);
  display: block;
  width: auto;
  overflow: hidden;
}

.post-navigation a {
  display: block;
  width: 50%;
  float: left;
  margin: 1em 0;
}

.post-navigation .next {
  text-align: right;
}

/**
 * Posts: hover effect pop-up;
 */

/* Show hover links with dotted underline */
a.hover-tip {
  border-bottom: 2px dotted $brand-color;
  color: black;
}

/* Hide underline when hovering */
a.hover-tip:hover {
  border-bottom: 2px dotted $yellow-color;
  text-decoration: none;
} 

/* Format the title */
a.hover-tip strong {
  line-height: 2em;
}

a.hover-tip span {
  display:none; /* hidden until hover */
  z-index:10; 
  padding:14px 20px;
  margin-left:28px;
  width:300px; 
  line-height:1.3em;
  border-radius:4px;
  box-shadow: 5px 5px 8px #CCC;
}

a.hover-tip:hover span {
  display:inline;
  position:absolute; 
  color:#111;
  border:1px solid #DCA;
  background: #fffAF0;
  font-weight: 300;
}

.hover-callout {
  z-index:20;
  position:absolute;
  top:10px;
  border:0;
  left:-12px;
}

/**
 * Posts: quest box
 */

.quest {
  background: #fff;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 18px;
  border: 3px solid $brand-color;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 1em;
  margin-bottom: $spacing-unit;

  .quest-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .quest-giver {
    width: 100%;
    height: 100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-image:url('/assets/img/unbricked_fig-quest_giver.jpg'); 
    background-position: top center;
    background-size: cover;
  }

  .quest-text {
    background-color: #fff;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 1.5em 1.5em 1.5em 1.5em;

    .quest-title {
      font-family: 'Roboto Mono', monospace;
      font-weight: 700;
      @include relative-font-size(1.3);
      letter-spacing: -1px;
      margin-bottom: 0.5em;
    }
    
    .quest-desc {
      font-style: italic;
    }
  }

}

/**
 * 404 page
 */

.status404 {
  text-align: center;

  p {
    font-weight: 300;
    font-size: 1.1em;
    font-style: italic;
  }

  img {
    margin-top: $spacing-unit;
  }
}
