@charset "utf-8";

// Define defaults for each variable.

$base-font-family: 'Roboto', sans-serif !default;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #333 !default;
$background-color: #fdfdfd !default;
$brand-color:      #ff304c !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 30%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$yellow-color:     #FFE330 !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1200px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
